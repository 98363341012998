import React from 'react'
import PhysicianLandingPage from 'containers/physician-landing-page'

const AimeeRuizLacLandingPage = () => (
  <PhysicianLandingPage
    physician="Aimee Ruiz L.Ac."
    institution="Aimee Ruiz L.Ac."
    referralCode="AIMEERUIZLAC"
    physicianURL="http://nidowellness.com/"
  />
)

export default AimeeRuizLacLandingPage
